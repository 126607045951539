@import '../../vars.scss';
.error-field {
  .hint {
    color: $error;
  }
}
.account-activation {
  .input-term-policy {
    .checkboxItem {
      align-items: flex-start !important;
      flex-wrap: nowrap !important;
      .ui.checkbox {
        & + label {
          line-height: 1.2 !important;
        }
      }
    }
  }
}
