@import '../../../../vars.scss';

.input-term-policy {
  .checkboxItem {
    display: flex;
    align-items: center;
    column-gap: 4px;
    flex-wrap: wrap;
    .ui.checkbox {
      cursor: pointer;
      label {
        cursor: pointer;
      }
      & + label {
        text-align: left;
        font-size: $fontSize - 2px !important;
        text-transform: inherit !important;
        font-weight: 400 !important;
        flex: 1;
        &::before,
        &::after {
          cursor: pointer !important;
        }
        a {
          color: $primary !important;
        }
      }
      .star {
        position: absolute;
        left: 19px;
        top: -2px;
      }
    }
  }
  &.error-field {
    .checkboxItem {
      color: $error;
    }
  }
}
