@import '../../vars.scss';
.Login {
  background: url(../../assets/img/login-banner.jpg) !important;
  background-size: cover !important;
  .provider-header {
    &:after {
      display: none;
    }
  }
  .sub-title {
    color: $white;
    text-align: center;
    font-size: $fontSize + 2;
  }

  .container.container {
    padding: $padding + 5px;
    .field {
      max-width: $inputWidth + 10px;
    }
    .title {
      text-align: center;
      color: $white !important;
      font-size: $fontSizeLarge !important;
      font-family: 'Ubuntu-700';
      font-weight: unset;
    }
    .link {
      display: block;
      text-align: center;
      cursor: pointer;
    }
    form {
      position: relative;
      margin-top: 4em;
      .title {
        font-family: 'Ubuntu-400' !important;
      }
    }
    .header.error {
      font-size: $fontSize - 4px !important;
      padding: $padding - 10px !important;
      display: flex;
      align-items: center;
    }
  }
  .error-cotent {
    display: block;
    background: $white;
    padding: 5px 10px;
    margin-top: 5px;
    border-radius: 4px;
    min-height: 42px;
    align-items: center;
    display: flex;
  }
}
