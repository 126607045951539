@import '../../../vars.scss';
.modal .has-custom-buttons:has(div.btn-send-final-review-list) {
  grid-template-columns: 1fr 201px !important;
}

.patient-contacting-container {
  .filter-pane {
    margin: 0px !important;
    padding-left: 0px;
    .field {
      display: inline-flex;
      grid-gap: 10px;
      margin-right: 10px !important;

      label {
        margin-top: 10px;
        text-transform: none !important;
        font-weight: 500 !important;
        line-height: unset;
      }

      .dropdown {
        width: 185px;
        height: 40px;
      }

      .ui.scrolling.dropdown .menu {
        min-width: 100.9% !important;
      }

      .divider.text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &.filterByValueDropdown .dropdown {
        width: max-content !important;
        min-width: 200px !important;
        max-width: 250px !important;

        .icon:before {
          right: 10px !important;
          position: absolute;
        }
      }
    }

    .form {
      display: inline-flex;
      margin-left: 0px;
      height: 44px;

      .input {
        width: 180px !important;

        @include ipadSize {
          width: 140px !important;
        }
      }

      .action-buttons {
        .field {
          margin-right: 5px !important;
        }
        .button {
          text-transform: none !important;
        }
      }
    }

    .filter-controls {
      display: grid !important;
      grid-template-columns: max-content max-content;
      justify-content: right;
      align-items: center;

      .download-pdf {
        display: block;
        button {
          width: 58px;
          padding-left: 6px;
          i.download.icon {
            float: right;
            margin-right: -14px;
            color: #ffffff !important;
          }
        }
        // Add styles for mobile view
        @media (max-width: 768px) {
          button {
            i.download.icon {
              margin-left: 3px; // Apply 3px margin-left for mobile
            }
          }
        }
      }
      .download-csv {
        display: block;
        button {
          width: 58px;
          padding-left: 6px;
          i.download.icon {
            float: right;
            margin-right: -14px;
            color: #ffffff !important;
          }
        }
        // Add styles for mobile view
        @media (max-width: 768px) {
          button {
            i.download.icon {
              margin-left: 3px; // Apply 3px margin-left for mobile
              margin-bottom: -11px;
            }
          }
        }
      }
    }

    .row {
      padding: 0px !important;
      .column {
        padding: 0px;
      }

      .rdo-btn-list {
        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: 10px;
        padding-left: 70px;

        @include ipadSize {
          padding-left: 0px !important;
        }
        label {
          &:after {
            content: ':';
          }

          &:first-child {
            width: 130px;
          }
        }
        .item {
          float: left;
          .radio {
            margin-right: 10px;
            margin-top: 2px;
            label {
              font-weight: 400 !important;
              padding-left: 20px !important;
            }
          }
        }
      }
    }

    @include mobileSize {
      .action-buttons {
        button {
          margin: 0px;
          padding: 1rem !important;
        }
      }
    }
  }

  .pp-table-secondary.align-text-bottom {
    tbody {
      .patient-details {
        .actions-items {
          display: inline;
          .edit-icon {
            height: 15px;
          }
        }
      }

      button {
        padding: 8px;
        text-transform: none !important;
      }

      .patient-missing-info {
        .inline.field {
          margin-bottom: 5px;
        }

        .download-pdf {
          display: block;
          button {
            padding-left: 0px;
            font-size: smaller;
            i.download.icon {
              float: right;
              margin-left: 3px;
            }
          }
        }
      }

      .finalReviewActionButtons {
        vertical-align: text-top;
        text-align: center;
      }

      .processing-status-hint {
        font-style: italic;
        font-size: x-small;
      }
    }
  }
}

.modal-popup-patient-provider-contacting {
  .close.icon {
    top: 1rem;
    right: 0.5rem;
    color: $dark;
  }

  .header {
    border: none !important;
    padding-bottom: 0px !important;
  }

  .patient-data {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;

    label {
      font-weight: 700;
      text-align: left;
      &:after {
        content: ':';
      }
      &.label-input-field {
        padding-top: 5px;
      }
    }

    .field.inline {
      margin-bottom: 0px !important;
    }

    input {
      padding: 0px 10px;
      height: 30px;
      width: 135px;
    }
  }
  .actions {
    border: none !important;
    padding-top: 0px !important;
    background-color: $white !important;
    .button.ui {
      text-transform: none !important;
    }
  }
}
