.actionButtons {
  vertical-align: text-top;
  text-align: center;
}

.filter-controls {
  display: grid !important;
  grid-template-columns: max-content max-content;
  justify-content: right;
  align-items: center;
}
