body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu-400', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Ubuntu'; /*Can be any text*/
  src: url('./assets/fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu-400'; /*Can be any text*/
  src: url('./assets/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Ubuntu-500'; /*Can be any text*/
  src: url('./assets/fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu-700'; /*Can be any text*/
  src: url('./assets/fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
}
