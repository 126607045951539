@import '../../../vars.scss';

.provider-subheader {
  display: flex;
  height: 67px;
  align-items: center;
  flex: 0 0 67px;
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px solid $secondary;

  .titles {
    flex: 1;
  }
  .breadcrumb {
    .section {
      text-transform: uppercase;
      font-size: $fontSize + 6px;
      font-weight: 700;
      cursor: default;
    }
    a {
      color: $secondary !important;
      text-transform: uppercase;
      font-weight: 700;
      cursor: default !important;
      & + .right.angle.icon {
        color: $primary;
        font-size: 1.2em;
      }
    }
  }
  .actions {
    display: flex;
    grid-gap: $padding - 7;
    align-items: center;
  }
}

.provider-subheader.termsPageHeader {
  padding-left: 20px !important;
}
