@import '../../../vars.scss';

.patient-missing-info-container {
  ul {
    margin-bottom: 0px;
  }

  .form {
    label {
      text-transform: none !important;
      color: $dark !important;
    }
  }

  .filter-pane {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    .form {
      display: grid;
      grid-template-columns: max-content max-content max-content;
      grid-gap: 5px;
      .field.inline {
        display: grid;
        grid-template-columns: 1fr 200px;
        justify-content: center;
      }
      .field {
        margin-bottom: 0px !important;
      }

      .clear-search button {
        text-transform: none !important;
        padding-left: 5px !important;
      }
    }
  }
  .patient-info-panel {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    padding: 0.7em 1em;

    .patient-data {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: 5px;
      label {
        text-align: left;
        font-weight: 700;

        &:after {
          content: ':';
        }
      }

      .patient-refresh {
        margin-left: 5px;
        font-size: 0.8em;
      }

      @include ipadSize {
        label {
          text-align: left;
          width: 170px !important;
        }
      }
    }

    .add-info-button-container {
      margin-top: auto !important;
      display: grid !important;
      justify-content: right;
      div {
        float: right;
        &.generate-pdf {
          margin-bottom: 10px;
        }
      }
      button {
        text-transform: none !important;
      }
    }
  }
  .missing-info-panel {
    padding: 0.7em 1em;

    .error-message {
      display: inline-block;
    }

    .row {
      .column {
        margin-right: 0px !important;
      }
    }

    .next-attempt-section {
      margin-top: 10px !important;
      .field {
        display: grid;
        grid-template-columns: max-content max-content;
        float: left;
        grid-gap: 10px;
        label {
          padding-top: 2px !important;
        }
        .radio {
          margin-right: 10px;
          @include ipadSize {
            width: 45px;
          }
          label {
            font-weight: 400 !important;
            padding-left: 20px !important;
          }
        }
      }
    }

    .field {
      display: flex;
      margin-bottom: 5px !important;

      &.inline {
        justify-content: left;
        margin-left: 0px !important;
        @include mobileSize {
          max-width: 100% !important;
        }
      }

      label {
        &:after {
          content: ':';
        }
      }

      .react-datepicker__input-container {
        display: grid;
        align-items: center;
      }
    }

    .view-mode-sales-order {
      display: grid !important;
      grid-template-columns: repeat(4, auto);
      justify-content: left;
      grid-gap: 30px;

      @include mobileSize {
        display: inline-block !important;
        grid-template-columns: repeat(1, auto);
        grid-gap: 5px;

        .field {
          margin-right: auto;
          max-width: 100% !important;

          label {
            width: 170px !important;
          }
        }
      }

      label {
        margin-top: 2px;
        margin-right: 4px;
      }
    }

    .list-section {
      .error-message {
        padding-left: 1rem;
      }

      .column {
        display: grid;
        grid-template-rows: max-content;
        grid-gap: 10px !important;
        margin-bottom: 10px;
        padding-right: 0px;

        .checkbox-list-container {
          border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
          padding-left: 10px;
          height: fit-content;

          .checkbox-list-items {
            padding-top: 5px !important;
            padding-bottom: 5px !important;

            .child {
              margin-left: 20px !important;
            }

            .nested-list {
              border-left: none !important;
              padding-left: 0px !important;
              width: 90% !important;

              .checkbox-list-items {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 5px;
                width: 100%;
                padding-left: 10px;

                &:first-child:nth-last-child(1) {
                  grid-template-columns: repeat(1, 1fr);
                }

                @include laptopSize {
                  grid-template-columns: repeat(3, 1fr);
                  grid-gap: 10px;
                }
              }
            }
          }
        }
      }
    }

    .comments-section {
      padding-top: 0px;
      .comment-field {
        max-width: 100% !important;
        .item {
          width: 100% !important;
          .hint {
            float: right;
            color: #b9b9b9;
          }
        }
      }
      .actions {
        display: grid !important;
        justify-content: end;

        align-content: end;
        text-align: right !important;
        padding-left: 0px;
        .buttons {
          display: grid;
          grid-template-columns: 2fr auto;
          align-items: end;
          margin-bottom: 10px;
          float: right;

          &:has(> div:nth-child(3):nth-last-child(1)) {
            @at-root .buttons {
              grid-template-columns: 3fr auto auto !important;
            }

            // You can also target the children specifically
            > div {
              grid-gap: 15px;
            }
          }

          // &.swap-action-buttons {
          //   grid-auto-flow: dense;

          //   div:first-child {
          //     grid-column: 2;
          //   }
          // }

          button {
            text-transform: none !important;
          }

          label {
            margin: 0px !important;

            &:after {
              content: none;
            }
          }
        }
      }
    }

    &.view-mode {
      .list-section {
        .column:empty {
          display: none !important;
        }
        .custom-checkbox {
          .item {
            cursor: default !important;
          }
          .ui.checkbox input.hidden + label {
            cursor: default !important;
          }
        }
      }

      .checkbox {
        label {
          &:before {
            border: none !important;
          }
        }
      }
      .comments-section {
        .field {
          float: left;
          max-width: 100%;
          label {
            margin-right: 10px;
            margin-top: 1.5px;
          }

          span {
            word-break: break-all;
          }

          @include ipadSize {
            display: block;
          }
        }
      }
    }
  }

  .contact-history {
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    padding: 0.7em 1em;

    label {
      font-size: larger;
    }

    .pp-table {
      margin-top: 10px;

      .status {
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}

/* Media query for mobile and tablet views */
@media (max-width: 992px) {
  .patient-info-panel {
    .patient-data {
      label {
        text-align: left;
        width: 170px !important;
      }
    }
  }

  .missing-info-panel {
    .next-contact-date-section {
      display: grid !important;
      justify-content: flex-start;
    }

    .field {
      label {
        text-align: left !important;
        width: 170px !important;
      }
    }

    .comments-section {
      label {
        width: 0px !important;
      }
      .comment-field {
        display: block;
      }
      .actions {
        display: grid !important;
        justify-content: end;

        label {
          width: 0px !important;
        }
      }
    }

    .list-section {
      label {
        width: 100% !important;
      }
    }
  }

  .contact-history {
    .pp-table {
      margin: 0px !important;
    }
  }
}

@media (max-width: 768px) {
  .filter-pane {
    .form {
      display: inline !important;
      width: 100% !important;
      .inline.field {
        float: left;
      }
      .search-button {
        float: right;
        margin-top: 5px !important;
      }
    }
  }

  .missing-info-panel {
    .field {
      label {
        text-align: left !important;
        width: 150px !important;
      }
    }

    .list-section {
      label {
        width: 100% !important;
      }
    }
  }
}
