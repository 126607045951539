@import '../../../../vars.scss';
.body-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .container.fluid.with-sidebar {
    display: grid;
    grid-template-columns: 180px 1fr;
    padding: 0 !important;
  }

  .container.fluid {
    .content {
      overflow: auto !important;
    }
    div.sidebar {
      padding: $padding - 7px;
      overflow: auto;
      h5.header {
        margin-bottom: 8px;
        // @include mobileSize {
        //   &::after {
        //     content: '<';
        //     float: right;
        //     font-weight: 700;
        //   }
        // }
      }
      .form {
        input {
          min-height: auto !important;
          height: 25px !important;
        }
      }
    }
  }
  .form {
    input,
    button,
    label {
      font-size: $fontSize - 2px !important;
      text-transform: initial !important;
    }
    input {
      min-height: $miniInput;
    }
    // https://betternight-sleepdata.atlassian.net/browse/BET-5513
    // Commented below code as per the above comment given in the above ticket.
    // label {
    //   color: $dark !important;
    // }
    button {
      width: 100%;
      height: $defaultButton;
    }
  }
}
