@import '../../../vars.scss';

.pagination-container {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
  position: sticky;
  bottom: 0;
  padding: 5px 0;
  background: $page-bg-hard;
  z-index: 1;
  .col {
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
    font-weight: 600;
  }
  .ui.pagination.menu {
    background: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
    min-height: auto !important;
    grid-gap: 5px;
    .item {
      padding: 0 3px !important;
      min-width: auto !important;
      color: $secondary !important;
      border-bottom: 2px solid transparent;
      font-weight: 600;
      &.active {
        border-color: $secondary;
        background: transparent !important;
      }

      &:before {
        display: none;
      }
    }
    & + div {
      font-weight: normal;
      font-size: 95%;
      input {
        border: 1px solid $secondary;
        color: $secondary;
        font-size: $fontSize - 2px;
        margin-left: 8px;
        outline: none;
        width: 30px;
        height: 16px;
        font-size: 9px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .ui.dropdown {
    border-bottom: 2px solid $secondary;
    min-width: 48px;
    > .angle.right {
      position: absolute;
      right: 0px;
      margin-right: 0;
      color: $primary;
      transition: 0.2s ease-in-out;
    }
    .menu {
      border-radius: 0;
      margin-top: 2px;
      .item {
        &:hover,
        &.selected {
          background: $secondary;
          color: $white;
        }
      }
    }
  }
  .no-result {
    width: 100%;
    background: $white;
    color: $dark;
    display: flex;
    place-content: center;
    padding: 25px;
    margin-top: -15px;
  }

  .label-has-one-record {
    .item {
      float: right;
      padding-left: 5px;
    }
  }
}
.ui.active {
  .angle.right {
    transform: rotate(90deg);
  }
  &.upward {
    .angle.right {
      transform: rotate(-90deg) !important;
    }
  }
}
.page-control-popup {
  padding: 5px 5px 10px !important;
  width: 77px;
  input {
    border: 0;
    background: rgba(36, 56, 117, 0.1);
    outline: 0;
    font-size: 9px;
    height: 18px;
    width: 100%;
  }
  .mini.button {
    height: 18px;
    line-height: 1;
    padding: 0;
    font-size: 9px;
    margin-top: 5px;
  }
}

.lastItem [type='lastItem'],
.firstItem [type='firstItem'],
.lastItem [type='nextItem'],
.firstItem [type='prevItem'] {
  pointer-events: none;
  opacity: 0.3;
}

.dropdown-col {
  margin-left: auto;
  .inline {
    display: flex;
    grid-gap: 5px;
  }
}
.table {
  margin-bottom: 10px !important;
  tr {
    td {
      padding: 0px 0.78571429em !important;
      position: relative;
      &.padding-left-0px {
        padding-left: 0px !important;
      }
    }
    th {
      padding: 0px 0.78571429em !important;
      &.showUpAndDownArrow {
        .cell {
          display: grid;
          grid-template-columns: max-content 1px;
          .upDownArrows {
            display: grid;      
            margin-bottom:7px !important;      
            .icon {
              line-height: 2px;
              height:0px !important;
              
            }
          }
        }
      }

      .cell {
        display: inline-flex;
        min-height: 36px;
        width: 100%;
        margin-right: 10px;
      }
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  .cell {
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1.2;
  }

  .actions-items {
    display: flex;
    grid-gap: 1.2em;
    img,
    span {
      cursor: pointer;
    }
    span {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-position: center !important;
      background-repeat: no-repeat !important;
      &.edit-icon {
        background: url($editIcon);
  
        &:hover {
          background: $secondary url($editIconHover);
        }
      }
      &.send-icon {
        background: url($sendIcon);
        &:hover {
          background: $secondary url($sendIconHover);
        }
      }
      &.people-icon {
        background: url($peopleIcon);
        &:hover {
          background: $secondary url($peopleIconHover);
        }
      }
      &.navigation-icon {
        background: url($navigationIcon);       
      }
    }
  }
}
