.reporting-container {
  .report {
    display: flex;

    align-items: center;
    line-height: 1.2;

    background-color: #f5f5f5;
    padding: 5px 15px;
    margin: 5px 15px;
    min-height: auto;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      margin-right: 10px;
    }
    .react-datepicker-wrapper {
      margin-right: 10px;
      .react-datepicker__calendar-icon {
        right: 5px !important;
      }
      .react-datepicker__view-calendar-icon input {
        padding-left: 10px !important;
        padding-right: 20px !important;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
        line-height: 1.21428571em;
        width: 200px !important;
      }
    }
  }
}
