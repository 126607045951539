@import '../../../vars.scss';
.Provider-Sidebar {
  h5.ui.header {
    padding: 30px 15px 10px;
    margin-bottom: 0;
    font-size: 15px;
    text-transform: uppercase;
    color: #c4c4c4 !important;
  }
  &.ui.overlay {
    width: 190px !important;
    top: 62px;
    height: calc(100vh - 112px) !important;
    h5.ui.header {
      padding: 30px 15px 10px;
      margin-bottom: 0;
      font-size: 15px;
      text-transform: uppercase;
      color: #c4c4c4 !important;
    }
    &.menu {
      .item {
        color: $secondary !important;
        text-transform: uppercase;
        font-weight: 600;
        padding: 1.65em 1em !important;
      }
      .disabled.item {
        color: $gray !important;
      }
    }
  }
}
