@import '../../vars.scss';

.missing-information {
  .provider-subheader {
    border-bottom: 0;
    align-items: flex-end;
    height: 45px;
  }
  .pp-tab-menu {
    .menu {
      overflow: auto;
      .item {
        span:only-child {
          padding-bottom: 4px;
        }
      }
    }
    .segment.tab {
      padding: 0;
      .desc {
        margin: 0;
        padding: 1em;
      }
      .table {
        border-radius: 0;
        th {
          &:hover {
            background: #f9fafb;
          }
          &.noSort {
            &:hover {
              cursor: default;
            }
            .cell {
              margin-right: 0px;
            }
          }
        }
        tbody {
          tr {
            vertical-align: baseline;
            div.sugarProviderName {
              margin-bottom: 10px !important;
            }
          }
          .actions-items {
            grid-gap: 0px;
            label:first-child {
              padding-top: 3px;
              width: 100%;
            }
          }
        }
      }
      .filter-pane {
        border-top: 1px solid $border;
        padding: 0.7em 1em;
        margin-top: 0px;
        display: flex;
        .filter-controls {
          margin-left: auto;
          .radio.checkbox {
            margin-left: 15px;
            label {
              padding-left: 1.4em;
            }
          }
        }
      }
    }
  }
}

/* Media query for mobile and tablet views */
@media (max-width: 768px) {
  .pp-table {
    margin: 0 10px;
  }
}
