@import '../../../vars.scss';

.modal {
  .actions.has-custom-buttons {
    display: grid;
    grid-template-columns: 1fr 80px;
    .text-transform-none {
      .button {
        text-transform: none !important;        
      }
    }   

    @include mobileSize {
      padding: 5px !important;
    }
    
  }  
}
