@import '../../../vars.scss';
.sidebar {
  .form .field label {
    text-transform: initial !important;
  }
  .ui.scrolling.dropdown .menu {
    @include min-laptopSize {
      max-height: 270px !important;
    }
    @include laptopLengthSize {
      max-height: 225px !important;
    }
  }
  .ui.checkbox {
    margin-bottom: 1em;
    label {
      line-height: 1.2;
      font-weight: 600 !important;
      color: #273860 !important;
      &::before {
        top: 2px !important;
      }
    }
  }

  .ui.radio {
    label {
      &::before {
        top: 1px !important;
      }
    }
  }
  .filter-label {
    & > label {
      margin-bottom: 12px !important;
      & + div {
        .ui.checkbox {
          label {
            margin-bottom: 0 !important;
            color: rgba(0, 0, 0, 0.87) !important;
          }
        }
      }
    }
  }
  .ui.mini.label,
  .ui.mini.labels .label .wrapper {
    width: auto;
    word-wrap: break-word !important;
    margin-bottom: 2px;
  }
}

.checkbox-status.field {
  position: relative;
  padding-left: 20px;
  & > label {
    font-size: 12px !important;
  }

  .ui.input {
    position: absolute;
    left: 0px;
    width: 15px !important;
    top: 0;
    input {
      height: auto !important;
    }
  }
}
