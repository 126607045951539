@import '../../../vars.scss';

.provider-contact-missing-info-container {
  .filter-pane {
    .field {
      display: inline-flex;
      grid-gap: 10px;
      label {
        margin-top: 10px;
      }
      .dropdown {
        width: 185px;
        height: 40px;
      }
      .ui.scrolling.dropdown .menu {
        min-width: 100.9% !important;
      }
    }

    .form {
      display: inline-flex;
      margin-left: 10px;
      height: 38px;

      .input {
        width: 140px !important;
      }

      .action-buttons {
        margin-left: 10px;

        .button {
          text-transform: none !important;
        }
      }
    }

    .filter-controls {
      display: grid !important;
      grid-template-columns: max-content max-content;
      justify-content: right;

      .download-csv {
        display: block;
        button {
          width: 58px;
          padding-left: 6px;
          i.download.icon {
            float: right;
            margin-right: -14px;
            color: #ffffff !important;
          }
        }
        // Add styles for mobile view
        @media (max-width: 768px) {
          button {
            i.download.icon {
              margin-left: 3px; // Apply 3px margin-left for mobile
              margin-bottom: -11px;
            }
          }
        }
      }
    }

    .row {
      .column {
        @include mobileSize {
          padding: 0px;
        }
      }
    }
  }
}

/* Media query for mobile and tablet views */
@media (max-width: 768px) {
  .provider-contact-missing-info-container {
    .filter-pane {
      margin: 0px !important;
      .form {
        display: inline !important;
      }
    }
  }
}
