@import '../../vars.scss';
.glossary {
  .container.fluid {
    padding: 0 !important ;
    .content {
      padding: 15px !important;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      background: $white;
      li {
        display: grid;
        grid-template-columns: 205px 1fr;
        grid-gap: 0.5em;
        align-items: center;
        padding: 4px 36px 4px 10px;
        min-height: 43px;
        border: 1px solid $gray;
        position: relative;
        line-height: 1.2;
        @include mobileSize {
          display: block;
        }
        &:before {
          content: '';
          display: block;
          height: 70%;
          border-right: 2px solid $gray;
          position: absolute;
          left: 215px;
          @include mobileSize {
            display: none;
          }
        }
        label {
          font-weight: bold;
          padding-right: 20px;
        }
        p {
          margin: 0;
          color: $dark;
          line-height: 1.2;
        }
        img {
          cursor: pointer;
          margin: 0 auto;
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}
.GlossaryEditModal {
  .sub-fields {
    .item {
      padding-top: 1px !important;
    }
  }
  .actions {
    small {
      display: block;
      margin-bottom: 8px;
      font-weight: 600;
      color: $secondary-2;
    }
    .btn-secondary {
      margin-right: -53px;
      position: relative;
      z-index: 1;
    }
  }
}
