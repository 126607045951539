.field {
  .password-field {
    position: relative;
    input {
      padding-right: 30px !important;
    }
    .icon.eye {
      position: absolute;
      right: 8px;
      top: 12px;
      margin: auto;
      cursor: pointer;
      color: #979797;
    }
  }
}
.massive.form {
  .icon.eye {
    top: 25px;
  }
}
