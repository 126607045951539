@import '../../vars.scss';
.termsPolicy {
  padding: 10px;
  p {
    line-height: 1.5em;
    margin-bottom: 1em;
  }
  .bold {
    font-weight: 500;
  }
  .link {
    color: $primary !important;
  }

  ul {
    list-style: square;
    padding-left: 20px;
    li {
      margin-bottom: 0.8em;
    }
    & ::marker {
      color: $primary;
      font-size: 16px;
    }
  }
}

.provider-subheader ~ div.termsPolicy {
  padding-left: 1em !important;
}
