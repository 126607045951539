@import '../../../vars.scss';
.provider-footer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  background: $footer-background;
  justify-content: space-between;
  color: $white;
  flex-shrink: 0;
  margin-top: auto;
  .item {
    cursor: default;
  }
  a {
    color: $white;
    text-decoration: underline;
    cursor: pointer;
    & + a {
      margin-left: 20px;
    }
  }
}
