@import '../../vars.scss';

.ui.striped.table {
  tbody {
    tr {
      &:nth-child(2n) {
        background: $white;
      }
    }
    tr:has(div.inactive) {
      background: $light-gray;
    }
  }
}
