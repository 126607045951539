@import '../../../vars.scss';
.audits-container {
  .filter-pane {
    margin: 0px !important;
    padding-left: 0px;
    .field {
      display: inline-flex;
      grid-gap: 10px;
      margin-right: 10px !important;

      label {
        margin-top: 10px;
        text-transform: none !important;
        font-weight: 500 !important;
        line-height: unset;
      }

      .dropdown {
        width: 185px;
        height: 40px;
      }

      .ui.scrolling.dropdown .menu {
        min-width: 100.9% !important;
      }

      .divider.text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &.filterByValueDropdown .dropdown {
        width: max-content !important;
        min-width: 200px !important;
        max-width: 250px !important;

        .icon:before {
          right: 10px !important;
          position: absolute;
        }
      }
    }

    .form {
      display: inline-flex;
      margin-left: 0px;
      height: 44px;

      .input {
        width: 180px !important;

        @include ipadSize {
          width: 140px !important;
        }
      }

      .action-buttons {
        .field {
          margin-right: 5px !important;
        }
        .button {
          text-transform: none !important;
        }
      }
    }

    .filter-controls {
      text-align: right;

      .date-range-picker {
        .react-datepicker-wrapper {
          width: 210px;
          svg {
            top: 6px;
          }
        }
        .react-datepicker-popper {
          z-index: 3 !important;
        }
      }
    }

    .row {
      padding: 0px !important;
      .column {
        padding: 0px;
      }
    }

    @include mobileSize {
      .action-buttons {
        button {
          margin: 0px;
          padding: 1rem !important;
        }
      }
    }
  }
  .pp-table-secondary {
    tr td {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }
}
