@import '../../../../vars.scss';
.button.ui {
  background: $primary-gradient !important;
  color: $white !important;
  text-transform: uppercase !important;
  &:disabled {
    background: #c4c4c4 !important;
    opacity: 1 !important;
  }
}
.btn-secondary {
  .ui.button {
    box-shadow: none !important;
    color: $primary !important;
    background: transparent !important;
    &:hover,
    &:focus {
      background: transparent !important;
    }
  }
}
