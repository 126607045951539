@import '../../../vars.scss';

.ui.massive {
  font-size: 1em !important;
  input,
  .button {
    height: $largeInput;
  }
}

.form {
  a {
    color: $white !important;
  }
  input,
  .button {
    height: 42px;
  }
  .field {
    max-width: 290px;
    margin: 0 auto 1.4em !important;
    label {
      text-transform: uppercase !important;
      font-weight: 600 !important;
      color: $secondary-2 !important;
      font-size: $fontSize !important;
      line-height: 1;
      & + div {
        color: $dark;
      }
    }
    small {
      display: block;
      text-align: right;
      color: $secondary-2;
      font-weight: 600;
      font-size: $fontSize - 2px;
      margin-bottom: 2px;
      cursor: default;
    }
    .hint {
      display: block;
      font-size: 11px;
      line-height: 1.2;
      font-weight: 600;
      margin-top: 2px;
    }
    &.inline {
      display: grid;
      grid-template-columns: 1fr $inputWidth;
      justify-content: center;
      max-width: 550px;
      @include mobileSize {
        display: block;
        max-width: $inputWidth;
      }
      .ui.input {
        width: 100% !important;
      }
      & > label {
        text-align: right;
        padding-top: $labelAlignment;
      }
    }
    &.required {
      label {
        &:after {
          color: $secondary-2 !important;
        }
      }
    }
    &.empty-label {
      @include mobileSize {
        & > label {
          display: none !important;
        }
      }
    }
    &.mb-0 {
      margin-bottom: 0 !important;
    }
  }
}
.ui.header {
  color: $secondary !important;
}

.error {
  color: $error !important;
  font-size: $fontSize - 3px;
  line-height: 1.2;
  font-weight: 600;
}
.block.header.error {
  max-width: 350px;
  margin: 0 auto;
}
.react-datepicker__navigation {
  width: 32px !important;
  height: 32px !important;
}
.react-datepicker__year-read-view--down-arrow {
  top: 3px !important;
}
