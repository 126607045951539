@import '../../vars.scss';
.table tr {
  th{

    &.sorted{
      &::after{
        position: absolute;
        right: 8px;
        top:9px;
        pointer-events: none;
      }
    }
  }
.status-th {
  width: 25%;
  min-width: 240px;
  min-height: 36px;
  .cell {
    flex-wrap: nowrap;
  }
}
}
.image-container {
  display: inline-block !important;
  position: absolute;
  z-index: 9;
  left: 55px;
  width: 34px;
  cursor: pointer;
  top: 0px;
  height: 100%;
  img {
    width: 14px;
    pointer-events: none;
    margin: 8px auto 0;
  }
}

.refresh-icon-wrapper {
  span {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-position: center !important;
    background-repeat: no-repeat !important;

    &.refresh-icon {
      background: url($sendIcon);
      &:hover {
        background: $secondary url($sendIconHover);
      }
    }
  }
}

.patientStatusTooltip {
  .statusTitle {
    text-decoration: underline;
    text-align: center;
    margin-bottom: 10px;
  }
}

.status-cell {
  cursor: pointer;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 0.78571429em;
  @include mobileSize {
    position: relative;
    padding: 0;
  }
}
