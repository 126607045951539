@import '../../../vars.scss';
.ui.modal {
  &.session-expiry-modal {
    border: 1px solid $secondary;
    box-shadow: none;
    text-align: center;
    color: $secondary;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1em 1em 1.5em;
    max-width: 390px;
    .header,
    .actions {
      padding: 0 !important;
      border: 0 !important;
    }
    .header {
      color: $secondary !important;
      text-transform: uppercase;
      font-weight: 600;
    }
    .content {
      padding: 1em;
      line-height: 1;
    }
    .actions {
      grid-template-columns: 1fr;
      text-align: center;
      grid-gap: 15px;
      background: transparent;
      button {
        min-width: 190px;
        padding: 0.61em;
      }
    }
  }
}
