// Theme Colors
$primary: #f26722;
$secondary: #243875;
$secondary-2: #273860;
$error: #e90f0f;

$white: #ffffff;
$dark: #1a1a1a;
$gray: #c4c4c4;
$light-gray: #ccc;
$text: #979797;

$page-bg: rgba(36, 56, 117, 0.2);
$page-bg-hard: #d3d7e3;
$border: rgba(34, 36, 38, 0.15);

$header-background: linear-gradient(180deg, #eff4f9 33.93%, #ffffff 100%);
$primary-gradient: linear-gradient(180deg, #f39323 0%, #eb5b28 100%);
$footer-background: linear-gradient(180deg, #2a3b64 0%, #203157 100%);

$warningIcon: url('./assets/img/Warning.svg');

// SIZES
$largeInput: 66px;
$miniInput: 25px;
$defaultButton: 36px;
$fullHeight: 100%;
$padding: 15px;
$fontSize: 14px;
$fontSizeLarge: 32px;
$inputWidth: 290px;
$labelAlignment: 13px;
$laptopWidth: 1199px;
$ipadWidth: 991px;
$mobileWidth: 767px;
$smallMobileWidth: 575px;
$xsMobileWidth: 400px;
$laptopHeight: 767px;

@mixin min-laptopSize {
  @media only screen and (min-width: ($laptopWidth + 1)) {
    @content;
  }
}
@mixin laptopSize {
  @media only screen and (max-width: $laptopWidth) {
    @content;
  }
}

@mixin ipadSize {
  @media only screen and (max-width: $ipadWidth) {
    @content;
  }
}

@mixin mobileSize {
  @media only screen and (max-width: $mobileWidth) {
    @content;
  }
}

@mixin mobileSize-sm {
  @media only screen and (max-width: $smallMobileWidth) {
    @content;
  }
}
@mixin mobileSize-xs {
  @media only screen and (max-width: $xsMobileWidth) {
    @content;
  }
}

@mixin laptopLengthSize {
  @media only screen and (max-height: $laptopHeight) {
    @content;
  }
}

// ICONS
$editIcon: '/assets/img/edit-icon.svg';
$editIconHover: '/assets/img/edit-icon-white.svg';
$sendIcon: '/assets/img/send-icon.svg';
$sendIconHover: '/assets/img/send-icon-white.svg';
$peopleIcon: '/assets/img/people-icon.svg';
$peopleIconHover: '/assets/img/people-filled-icon.svg';
$navigationIcon: '/assets/img/navigation-icon.svg';
$navigationIconHover: '/assets/img/navigation-filled-icon.svg';
