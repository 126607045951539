@import '../../../vars.scss';
// LOADER
.loader-spin {
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1003; // Sidebar and Modal popup has value of near by 1002.  Hence, added more then that to display loader as full screen
  top: 0;
  left: 0;
  content: '';
  span {
    width: 76px;
    padding: 10px;
    background: $primary;
    aspect-ratio: 1;
    border-radius: 50%;
    --gradient: conic-gradient(transparent, #fff), linear-gradient(#fff 0 0) content-box;
    -webkit-mask: var(--gradient);
    mask: var(--gradient);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: spin 1s linear infinite;
  }
}
@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
