@import './vars.scss';
#root {
  height: $fullHeight;
  & > div {
    display: flex;
    flex-direction: column;
    height: $fullHeight;
    .container.fluid {
      flex: 1;
      width: 100% !important;
      margin: 0 !important;
      overflow: auto;
      padding: $padding;
      & > .content {
        background: $page-bg;
        // padding: $padding;
        padding: 0px 6px 6px;
        min-height: $fullHeight;
        h5.header {
          color: $secondary-2 !important;
          font-family: 'Ubuntu-400' !important;
          font-weight: 100 !important;
          padding-top: 15px;
        }
      }

      & > .content:has(div.pagination-container) {
        padding-bottom: 0px !important;
      }
    }

    .container.fluid:has(div.termsPageHeader) {
      padding: 0px !important;
    }
  }
  & > div.Toastify {
    height: 0px;
  }
}
.error-message {
  color: $error !important;
}
// PROVIDER FORM
.Provider-Form-Page {
  form {
    width: fit-content;
    margin: 25px auto 10px;
    .field {
      label {
        text-transform: uppercase !important;
        color: $secondary !important;
        font-weight: bold !important;
      }
    }
    @include mobileSize {
      width: 100%;
      margin: 10px auto;
    }
  }
}
// DEFAULT TABLE
.ui.striped.table:not(.pp-table-secondary) {
  border: 0;
  line-height: 1.2;
  thead {
    tr {
      th {
        background: $page-bg-hard !important;
        color: $secondary !important;
        border: 0 !important;
        border-radius: 0 !important;
      }
      th.hide {
        display: none !important;
      }
      th.noSort:hover {
        background: $page-bg-hard;
        cursor: default;
      }
      // &.sorted {
      //   background: #f2f2f2 !important;
      //   color: $secondary !important;

      //   &:hover {
      //     background: #f2f2f2 !important;
      //     color: $secondary !important;
      //   }
      // }
    }
  }
  tbody {
    tr {
      &:nth-child(2n) {
        background: #f5f5f5;
      }
      td.hide {
        display: none !important;
      }
    }
  }
}
.pp-table-secondary {
  &.align-text-bottom {
    thead {
      vertical-align: bottom !important;
    }
    th {
      background: #f9fafb !important;
      &.sorted {
        &:after {
          top: auto !important;
          bottom: 5px !important;
        }
        &.ascending:after {
          margin-bottom: 5px !important;
        }

        .icon {
          display: none !important;
        }
      }
    }
    .cell {
      align-items: flex-end;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  + .pagination-container:has(.col) {
    padding: 0px 10px;
    background: #f5f5f5;
  }
  + .pagination-container:has(.no-result) {
    padding-bottom: 0px;
  }
}

.ui.loader {
  &:after {
    background: url('./assets/img/bn-icon.png') !important;
    background-size: cover !important;
    border: 0;
  }
}
.Toastify__toast-theme--light {
  color: $secondary !important;
  background: $page-bg-hard !important;
}
.Toastify__progress-bar-theme--light {
  background: $secondary !important;
}
tr.inactive {
  background: $gray !important;
}

.provider-dropdown {
  .ui.scrolling.dropdown .menu {
    max-height: 215px;
    @include laptopLengthSize {
      max-height: 160px !important;
    }
  }
}

.padding-top-04px {
  padding-top: 4px !important;
}

.width-05-percent {
  width: 5% !important;
}

.width-08-percent {
  width: 8% !important;
}

.width-10-percent {
  width: 10% !important;
}

.width-12-percent {
  width: 12% !important;
}

.width-13-percent {
  width: 13% !important;
}

.width-14-percent {
  width: 14% !important;
}

.width-15-percent {
  width: 15% !important;
}

.width-17-percent {
  width: 17% !important;
}

.width-18-percent {
  width: 18% !important;
}

.width-20-percent {
  width: 20% !important;
}

.width-22-percent {
  width: 22% !important;
}

.width-25-percent {
  width: 25% !important;
}

.width-30-percent {
  width: 30% !important;
}

.width-35-percent {
  width: 35% !important;
}

.word-break {
  word-break: break-word;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.uppercase {
  text-transform: uppercase !important;
}

.text-transform-none {
  text-transform: none !important;
}

.cursorPointer {
  cursor: pointer;
}
.inline {
  display: inline-block;
}

.hide {
  display: none !important;
}

.GlossaryEditModal {
  .form {
    max-width: 100%;
    .field.inline {
      grid-template-columns: 150px 1fr;
      .sub-fields {
        .field.inline {
          margin-bottom: 0 !important;
          label {
            font-weight: normal !important;
            font-size: 12px !important;
            text-transform: capitalize !important;
          }
          grid-template-columns: 80px 1fr;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }
  .actions {
    border-top: 0 !important;
    background: transparent !important;
  }
  .header {
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
    color: $secondary !important;
    text-transform: uppercase;
  }
}
.nowrap {
  white-space: nowrap !important;
}
.two-factor-auth {
  p {
    max-width: 630px;
    margin: 0px auto;
    padding: 25px 20px;
    .link,
    a {
      color: $primary !important;
      cursor: pointer;
    }
  }
}
.btn-sm {
  button {
    font-size: 11px !important;
    padding: 7px 10px !important;
    width: 90px;
  }
  &.inactive {
    button {
      background: $secondary !important;
      // background: $gray !important;
    }
  }
  &.active {
    button {
      background: $secondary !important;
    }
  }
}

.react-datepicker__input-container {
  .react-datepicker__calendar-icon {
    right: 5px;
    cursor: pointer;
  }

  input {
    outline: none !important; /* Remove the outline when focusing */
    cursor: pointer !important; /* Indicate clickable input */
  }
}
