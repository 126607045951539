@import '../../vars.scss';
.error-field {
  .hint {
    color: $error;
  }
}

.topTitle {
  padding-top: 10px !important;
}
