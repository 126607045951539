@import '../../../../vars.scss';

.pp-tab-menu {
  .tabular.menu {
    padding: $padding - 5px $padding 10px;
    border: 0;
    min-height: auto;
    grid-gap: 1.1em;
    border-bottom: 1px solid $border;
    align-items: end;
    .item {
      border: 0;
      padding: 0;
      border-bottom: 4px solid transparent;
      color: $text;      
      .notification-badge {
        margin-left: 5px;
        padding: 6px 12px !important;
        background-color: #ff9e79 !important;  
        color: white !important;  
        font-weight: bold;        
      }
      &.active {
        padding-bottom: 2px;
        border-color: $primary;
        color: $secondary;
        font-weight: normal;
      }
    }
    .item:first-child {
      width: 122px;
      text-align: center;
    }
  }
  .segment.tab {
    border: 0;
    padding: $padding - 5px $padding;
  }
}
