@import '../../../../vars.scss';

.custom-checkbox {
  .item {
    display: flex;
    grid-gap: 5px;
    cursor: pointer;

    div.disabled {
      cursor: default;
    }
    .text,
    label {
      font-weight: 500 !important;
      line-height: 1.1;
      font-size: 13px;
      color: $dark !important;
      text-transform: none !important;
    }
  }

  .ui.toggle.checkbox {
    $width: 65px !important;
    width: $width;
    flex-shrink: 0;
    z-index: 0;
    input {
      width: $width;
      &:checked {
        & + label {
          &:before {
            background-color: $secondary !important;
          }
          &::after {
            left: 44px !important;
          }
        }
      }
    }
    label {
      padding-left: 72px;
      &::before {
        width: $width;
        background-color: #979797 !important;
      }
      &:after {
        left: 0px !important;
        z-index: 3 !important;
      }
    }

    &:before,
    &:after {
      position: absolute;
      top: 1.5px;
      z-index: 2;
      font-size: 11px;
      font-weight: 600;
      color: $white;
      left: 5px;
    }
    &[data-true]:before {
      content: attr(data-true);
      opacity: 0;
    }
    &[data-false]:after {
      content: attr(data-false);
      left: auto;
      right: 4px;
      opacity: 1;
    }
    &.checked {
      &::before {
        opacity: 1;
      }
      &::after {
        opacity: 0;
      }
    }
  }

  &.large {
    .ui.toggle.checkbox {
      $height: 30px !important;
      input {
        height: $height;
        &:checked {
          & ~ label {
            &::after {
              left: 35px !important;
            }
          }
        }
      }
      label {
        &::before {
          height: $height;
        }
        &::after {
          width: $height;
          height: $height;
        }
      }
      &:after,
      &::before {
        font-size: $fontSize + 1;
        top: 6px;
      }
    }
  }
}
